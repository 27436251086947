import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import Old2005BlogPage from "../../components/Old2005BlogPage";

const OldBlogPage = () => (
  <Layout>
    <Seo title="Old 2005 Blog—February 2007" />
	<Old2005BlogPage>
		<h2>February 2007</h2>




		<h3 className="blogdate">
		Wednesday, February 28, 2007
		</h3>






		<h4 className="blogitemtitle">
		Vorn Thunk
		</h4>





			<div className="blogitembody">
			As you can see, Jesse endorses <span style={{fontStyle: "italic"}}>Thunk</span> by Vorn.<br /><br />
			<em>Sorry, this picture is lost off the server, boo!</em><br /><br />It's my belief that Vorn is another of the musical brilliantalists in Wellington, New Zealand. Try him out at <a href="http://www.myspace.com/vornmusic">http://www.myspace.com/vornmusic</a> and enjoy. I recommend the whole CD. So does Holly, who is 10 years old, I think. She is often heard singing "the americans are going to kill us." I am babysitting. I need a haircut.<br /><br />
			<em>Sorry, I lost this picture, too!  I'm not sure that I'll regain these images.</em><br /><br />This blog entry made possible by Georgie's computer and webcam. Georgie is Holly's mom.
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 7:18 PM &nbsp;
			</div>






		<h3 className="blogdate">
		Monday, February 26, 2007
		</h3>






		<h4 className="blogitemtitle">
		Cuba Street Carnival
		</h4>





			<div className="blogitembody">
			I flew from Brisbane to Wellington on Friday night and awoke Saturday morning-or-was-it-afternoon to the Cuba Street Carnival/Festival and some fantastic weather.  What a beautiful day!  And evening!  I danced all day.<br /><br />I reckon some of my Canadian friends, perhaps even those that love Wilco, will love The Phoenix Foundation.<br /><br />Located at:<br /><a href="http://www.thephoenixfoundation.co.nz/">http://www.thephoenixfoundation.co.nz/</a><br /><a href="http://www.myspace.com/thephoenixfoundationnz">http://www.myspace.com/thephoenixfoundationnz</a><br /><br />Oh, look, on myspace.com they are friends with Spartacus R, whom I previously blurted to be awesome!
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 3:21 PM &nbsp;
			</div>






		<h3 className="blogdate">
		Thursday, February 01, 2007
		</h3>






		<h4 className="blogitemtitle">
		Let's have a bluebottle fight!
		</h4>





			<div className="blogitembody">
			What's a bluebottle, you ask?<br /><br />It's similar to a jellyfish.  It's venemous.  You can normally tell if they are in the water by walking along the beach - you will find many of them washed up on the sand.  You can see what they look like, and read more about them <a href="http://en.wikipedia.org/wiki/Portugese_man_o_war">here</a>.<br /><br />Several times during my January stay at Burleigh Heads in Gold Coast, I found these creatures on the shore and in the water.  I haven't been stung, but I'm quite cautious - I constantly scan for them towards the open sea, the shore, and the source-direction of the rip.  It's common to look forward and not see them, yet have one drift near you from the side, behind you!  I watched one fellow catch a wave and float right into one... yes, ouch.  They are very easy to spot - look for a water bubble on the surface with deep blue string around it.<br /><br />My friend Chopper, a born-and-raised Queenslander, reports that mates occasionally enjoy a bluebottle fight - picking them up off the sand and flinging them at each other! Ha ha, outrageous, I'm assuming they are <em>drunk</em> mates!  Bluebottles are easy to pick up by their air bladders, and can easily be flung a distance through the air - and knowing a bit about human nature, I believe Chopper's report.
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 2:55 PM &nbsp;
			</div>





		<p>
			<Link to="/blog2005/">Go back to the blog index page</Link>
		</p>
</Old2005BlogPage>
  </Layout>
)

export default OldBlogPage;
